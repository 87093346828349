<template>
  <div :class="!topBarShow ? 'blindBoxRule-container active' : 'blindBoxRule-container'
    ">
    <van-nav-bar v-if="topBarShow" @click-left="goBack" left-arrow :title="title" :border="false" />
    <div class="ruleBox" v-html="rule"></div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { NavBar, Toast } from "vant";
import { useStore } from "vuex";
import { isWeiChat } from "@/utils/environment";
import Api from "@/API";
export default {
  name: "blindBoxRule",
  components: {
    [NavBar.name]: NavBar,
  },
  setup(props) {
    //使用vuex
    const store = useStore();
    //使用路由
    const router = useRouter();
    // 顶部栏显示隐藏
    const topBarShow = !isWeiChat();
    //创建API对象
    const API = new Api();
    // 规则
    let rule = ref("");
    // 标题
    let title = ref("玩法规则");
    // 获取规则
    const getRule = async () => {
      let res = await API.getBlindBoxData({
        token: store.state.Token,
        luck_id: store.state.Luckid
      });
      if (res) {
        rule.value = res.data.content;
      }
    };
    //返回
    function goBack() {
      router.back();
    }
    onMounted(() => {
      getRule();
    });
    return {
      goBack,
      topBarShow,
      rule,
      title,
    };
  },
};
</script>

<style lang="scss">
:root {
  --van-nav-bar-icon-color: #000;
}

.blindBoxRule-container.active {
  .van-nav-bar {
    padding-top: 0;
  }
}

.blindBoxRule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .van-nav-bar {
    padding-top: 50px;
  }
}

.ruleBox {
  padding: 30px;
}
</style>